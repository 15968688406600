import { Component, OnInit, Input, Output, EventEmitter,ViewChild, ElementRef} from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../shared/common.service';
import { UrlConstants } from '../shared/url-constant';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  @Input() product: any;
  // @Input() heading: string;
  // @Input() slide_id: string;
  selected = 0;
  public userId: number;
  public rate: any[];
  seconds: any
  hovered = 0;
  readonly = false;
  public id: any;
  validatingForm: FormGroup;
  url = UrlConstants.getProductDetail;
  oneRating : any = 0
  twoRating : any= 0
  threeRating : any= 0
  fourRating : any= 0
  fiveRating : any= 0

  @Output() success = new EventEmitter();

  @ViewChild("loginButton", { static: true })
  public loginButton: ElementRef<HTMLElement>;

  constructor(config: NgbRatingConfig, private route: ActivatedRoute, private commonService: CommonService) {
    config.max = 5;
  }

  ngOnInit() {
    // const userData1 = localStorage.getItem('user');
    //   this.userId = userData1 ? JSON.parse(userData1).id : '';
    // this.commonService.get(this.url, { product_slug: this.id, user_id: this.userId ? this.userId : '' }) .subscribe((data) => {
    
    //     console.log(data)
    // });
    // this.Ratingapi();

    this.commonService.openLoginPopup().subscribe((data) => {
      if (data) {
        console.log(this.loginButton);
        this.loginButton.nativeElement.click();
      }
    });
   
    const userData = localStorage.getItem('user');
    this.userId = userData ? JSON.parse(userData).id : '';
    this.validatingForm = new FormGroup({
      contactFormModalName: new FormControl('', Validators.required),
      contactFormModalEmail: new FormControl('', Validators.email),
      contactFormModalSubject: new FormControl('', Validators.required),
      contactFormModalMessage: new FormControl('', Validators.required)
    });


   // this.ratingdata();
   this.calculateRatings();

  }

  calculateRatings(){
    this.product?.ratingList?.forEach((rate , i)=>{
      if(rate.rating == 5){
        this.fiveRating ++
      }
      if(rate.rating == 4){
        this.fourRating ++
      }
      if(rate.rating == 3){
        this.threeRating ++
      }
      if(rate.rating == 2){
        this.twoRating ++
      }
      if(rate.rating == 1){
        this.oneRating ++
      }

      if(i == this.product?.ratingList?.length - 1){
        this.fiveRating = (this.fiveRating / this.product?.ratingList?.length * 100) + "%";
        this.fourRating = (this.fourRating / this.product?.ratingList?.length * 100) + "%";
        this.threeRating = (this.threeRating / this.product?.ratingList?.length*100) + "%";
        this.twoRating = (this.twoRating / this.product?.ratingList?.length *100) + "%";
        this.oneRating = (this.oneRating / this.product?.ratingList?.length *100) + "%";
      }
    })
  }
  get contactFormModalName() {
    return this.validatingForm.get('contactFormModalName');
  }

  get contactFormModalEmail() {
    return this.validatingForm.get('contactFormModalEmail');
  }

  get contactFormModalSubject() {
    return this.validatingForm.get('contactFormModalSubject');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }
  rateproduct() {
    // alert("here");
    const data = this.contactFormModalMessage
    if (data.status == 'VALID') {
      this.commonService.post(UrlConstants.rateproduct, {
        device_id: 1234,
        user_id: this.userId, product_id: this.product.product_id_PK, rating: this.selected, reviews: data.value
      }).subscribe(res => {
        
        if (res.status) {
          this.success.emit("done");
          document.getElementById('ratingmodal').click()
        }
 
      })
    }
  }
  // Ratingapi() {
  //   this.commonService.get('https://themomskart.com/api/v1/webservice/getProductDetailWeb')
  //   .subscribe((data) => {
  //     console.log (data)
  //    });
  //   }
 //   this.commonService.get(UrlConstants.getProductDetail)
  //  .subscribe((data) => {
    //  if (data.status === true) {
    // this.rate = data.result
     // this.rate = data.ratingList;
   //   console.log(data)
  //    }
   // },
     // (error) => {
     //   console.log(error);
     // });
  

  ShowRatingModal(){
    // alert("here");
    if (this.userId != null && this.userId>0) {
      // alert("here1"+this.userId);
    } else {
      // this.loginButton.nativeElement.click();
    }
  }

  getdate(dat) {
    let aDay = 24 * 60 * 60 * 1000
    let date = new Date(dat - aDay)
    let today = new Date()
    // this.seconds = Math.floor((today - date) / 1000);

    var interval = Math.floor(this.seconds / 31536000);

    if (interval > 1) {
      return interval + " years";
    }
    interval = Math.floor(this.seconds / 2592000);
    if (interval > 1) {
      return interval + " months";
    }
    interval = Math.floor(this.seconds / 86400);
    if (interval > 1) {
      return interval + " days";
    }
    interval = Math.floor(this.seconds / 3600);
    if (interval > 1) {
      return interval + " hours";
    }
    interval = Math.floor(this.seconds / 60);
    if (interval > 1) {
      return interval + " minutes";
    }
    return Math.floor(this.seconds) + " seconds";
  }
  // rating = 0;
  // ratingdata(){
  //   this.product.ratingList.forEach(element => {
  //     this.rating += +(element.rating);
  //   });
  //   this.rating = this.rating / this.product.ratingList.length
  //  this.rating = Math.round(this.rating * 100) / 100;
  // }
}
