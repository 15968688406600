import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
 import { Product } from '../product';
import { CommonService } from '../shared/common.service';
import { UrlConstants } from '../shared/url-constant';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription, interval } from 'rxjs';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y
} from "swiper/core";
import Swiper from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
declare var $: any;
@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss']
})
export class ProductSliderComponent implements OnInit, AfterViewInit {
  // Snacks:[]
  @Input() Snacks: any[];
  @Input() heading: string;
  @Input() slide_id: string;
  @Input() nameofsale: string;
  @Input() idofsell: string;

  constructor(private commonService: CommonService, private ngxService: NgxUiLoaderService, private router: Router) { }
  public userId: number;
  public Nameofsale : any;
  public Idofsale : any ;
  screen = window.innerWidth
  user: any;
  saleId : number; 
  ngOnInit() {
    // this.saleApiCall();
    console.log(this.Snacks);
    
    const user = JSON.parse(localStorage.getItem('user'));
    this.user = user;
    if (user) {
      this.userId = user.id

    } else {
      this.userId = 0
    }
    
    this.subscription = interval(1000).subscribe(x => { this.getTimeDifference(); });
    
  }

  newTab(id) {
    console.log(id);
    
    let slug = id.replace(/\s+/g, "-") + "-?id=" + id + "&marketplace=MOMSKART";

    this.router.navigate(['productdescription/' + id])
    //  window.open('prodesc/' + id, '_blank');
    
  }

  async viewall(heading, snacks) {
    console.log(heading);
      console.log(this.Snacks);
      
    if (this.Snacks.length !== 0) {
      // alert(" >> "+heading);
      if (heading === 'Best Selling Snacks') {
        this.moveToCategoryPage(18, heading);
      }
      else if (heading === 'Best Selling Sweets') {
        this.moveToCategoryPage(17, heading);
      }
      else if (heading === 'Best Selling Staples') {
        this.moveToCategoryPage(19, heading);
      }
      else if (heading === 'Best Selling Pickles') {
        this.moveToCategoryPage(20, heading);
      }
      else if (heading === this.nameofsale ) {
        this.router.navigate(['sale']);
      }
      else {
        this.moveToCategoryPage(16, heading)
      }
      // Previously commented out code
      // this.Snacks.map((tab, i) => {
      //   if (i === 1) {
      //     if (tab[0].super_cat_id_FK !== undefined) {
      //       this.moveToCategoryPage(tab[0].superCatSlug, heading)

      //     } else {
      //       this.moveToCategoryPage(16, heading)

      //     }
      //   }

      // })
      // Previously commented out code
    }
    
    // View current best seller product in seperate page
    // this.router.navigate(['/currentBestsellerViewAll']);
  }

  
  async moveToCategoryPage(catId, catName) {
    console.log(catId,catName);
    
    sessionStorage.setItem("catName", catName);
    let slug =
      catName.replace(/\s+/g, "-") +
      "?cat_id" +
      catId +
      "&marketplace=MOMSKART";
    this.router.navigate(['/categorypage', slug]);

  }

  openseller(id, catName) {
    let slug =
      catName.replace(/\s+/g, "-") +
      "-?cat_id=" +
      id +
      "&marketplace=MOMSKART";
    this.router.navigate(['/seller', slug]);

  }
   addtoFav(productId) {
   const res = this.commonService.addToFav(productId, this.userId)
    if (res) {
       res.subscribe(data => {
         if (data.status === true) {
           let localarr = this.Snacks

          localarr.map(fav => {
            fav.map(favv => {
             if (productId === favv.product_id_PK) {
               if (favv.isFavorite === 0) {
                   favv.isFavorite = 1
                 } else {
                   favv.isFavorite = 0
                 }
               }
           })
           })
           this.Snacks = localarr
         } else {
           alert('Something went wrong, please try again later');
         }
       });
     }
   }

  ngAfterViewInit() {
    setTimeout(() => {
      const swiper = new Swiper('.swiper-container', {
        speed: 1200,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          640: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 50,
          },
        }
      })
    }, 5000);

  }
  onSwiper(swiper) {
 //   console.log(swiper)
  }
  onSlideChange() {
//    console.log('slide change')
  }

  // saleApiCall() {
  //   this.commonService.salewebAPI().subscribe((data) => {
  //  // get('https://themomskart.com/api/v1/webservice/saleWebAPI').subscribe((data) => {
  //       this.dateNow = new Date(data.start_date);
  //       this.dDay = new Date(data.end_date);
  //       this.Nameofsale = data.sale_name ;
  //       this.Idofsale = data.sale_id ;
  //   //    console.log('this.dateNow', this.dateNow, ' this.dDay',  this.dDay )
  //     },
  //       (error) => {
  //         console.log(error);
  //       });

  //   }
    private subscription: Subscription;
    public dateNow  //= new Date('April 06 2021 00:00:00');
    public dDay     //= new Date('April 04 2021 00:00:00');
    milliSecondsInASecond = 1000;
    hoursInADay = 24;
    minutesInAnHour = 60;
    SecondsInAMinute  = 60;

    public timeDifference;
    public secondsToDday;
    public minutesToDday;
    public hoursToDday;
    public daysToDday;


    private getTimeDifference () {
      if(this.heading == 'End of season sale'){
        this.timeDifference = this.dDay.getTime() - new  Date().getTime();
        //  console.log( "Hello check " ,this.timeDifference);
          this.allocateTimeUnits(this.timeDifference);
      }
       
    }

    private allocateTimeUnits (timeDifference) {
        this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
        this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
        this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
        this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
    }
   
   ngOnDestroy() {
      this.subscription.unsubscribe();
   }

}
function data(data: any) {
  throw new Error('Function not implemented.');
}