import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public userData: any;

  constructor(private commonService: CommonService, private router: Router,) { }

  ngOnInit(): void {
    this.userData = localStorage.getItem('user');
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
    }
  }
  logout() {
    localStorage.removeItem('user');
    // this.userData = null;
    // this.cartItemCount = 0;
    this.commonService.updateCartItemCount(0);
    window.open('/', "_self")
    this.router.navigate(['/logout']);
    // window.location.reload()
  }
  openModal() {
    
  }
}