import { FormGroup } from "@angular/forms";

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function getDiscountPercentages(
  price: any,
  discount: any,
  for_label: any
) {
  let int_p = parseFloat(price) * 2; //Original Price 300
  let int_d = parseFloat(discount); //Discount  price  250 
  let discount_rate = (int_d / int_p) * 100;
  switch (for_label) {
    case "item_price":
      let _price = int_p - (int_p * discount_rate) / 100;
      return _price;

      break;

    default:
      break;
  }
  return discount_rate > 0 && for_label == "discount"
    ? `You Save${discount_rate}%`
    : discount_rate > 0 && for_label == "price"
    ? discount_rate
    : "";
}
