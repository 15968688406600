import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private title: Title,
    private meta: Meta) {

  }

  ngOnInit() {
    this.title.setTitle('About us');
    this.meta.updateTag({ name: 'description', content: 'Momskart is an online store to provide you a wide range of homemade namkeen, mithai, pickles, spices, staples, biscuits, cookies, healthy products and many more at your doorsteps all over India. Buy best traditional food items from all over India ' });

  }

}
