// const baseURL = 'https://themomskart.com/api/v1/webservice/';
// const baseURL = 'https://themomskart.com/api/v1/index.php/customer_webapi/'
 const newBaseURL = 'https://momskart.live/api/v1/index.php/customer_webapi/';
 const commonBaseURL = 'https://momskart.live/api/v1/webapp/coupon/';
 const commonCheckoutURL = 'https://momskart.live/api/v1/webapp/';
 const baseURL = 'https://momskart.live/api/v1/webservice/';
// RRJMD JSM JSK
// const baseURL='https://developer.themomskart.com/api/v2/webservice/';
export const UrlConstants = {
    // PINCODE_URL : 'https://staging-express.delhivery.com/c/api/pin-codes/json/?token=1e9453b09c0b5557b07979118699c25063190a86&filter_codes=',
    // PINCODE_URL: baseURL + 'checkPincode/',
    PINCODE_URL: newBaseURL + 'delhivery/checkPincode/',
    getCity: newBaseURL + 'product/getProductCity/',
    getStates: newBaseURL + 'product/getProductState/',
    // getProductList: baseURL + 'getProductList',
    getProductList: newBaseURL + 'product/getProductList',
    // updatePassword: baseURL + 'updatePassword',
    updatePassword: newBaseURL + 'customer/updatePassword',
    // getProductDetail: baseURL + 'getProductDetailWeb/',
    getProductDetail: newBaseURL + 'product/getProductDetailWeb/',
    // addToCart: baseURL + 'addToCart',
    addToCart: newBaseURL + 'cart/addToCart',
    // getCartList: baseURL + 'getCartList',
    getCartList: newBaseURL + 'cart/getCartList',
    // generateToken: baseURL+'getToken',
    generateToken: newBaseURL+'payment/getToken',
    // getNewAddition: baseURL + 'getNewAddition',
    getNewAddition: newBaseURL + 'category/getNewAddition',
    // getHomeData: baseURL + 'getHomeData/',
    getHomeData: newBaseURL + 'home/getHomeData/',
    // getIndexData: baseURL + 'getHomeDataNew/',
    getIndexData: newBaseURL + 'home/getHomeDataNew/',
    // getMenuItems: baseURL + 'getMenuItems/',
    getMenuItems: newBaseURL + 'category/getMenuItems/',
    // urlSignup: baseURL + 'signup/',
    urlSignup: newBaseURL + 'customer/signup/',
    // urlLogin: baseURL + 'login/',
    urlLogin: newBaseURL + 'customer/login/',
    // forgotPassword: baseURL + 'otpLogin',
    forgotPassword: newBaseURL + 'customer/otpLogin',
    // checkOTP: baseURL + 'otpCheck',
    checkOTP: newBaseURL + 'customer/otpCheck',
    // getSubcategories: baseURL + 'getSubcategories',
    getSubcategories: newBaseURL + 'category/getSubcategories',
    // getCategoryBySuperCat: baseURL + 'getCategoryBySuperCat',
    getCategoryBySuperCat: newBaseURL + 'category/getCategoryBySuperCat',
    // addToFavourite: baseURL + 'addToFavourite',
    addToFavourite: newBaseURL + 'product/addToFavourite',
    // getCategoryAll: baseURL + 'getCategoryAll',
    getCategoryAll: newBaseURL + 'category/getCategoryAll',
    // getSuperCategoryList: baseURL + 'getSuperCategoryList',
    getSuperCategoryList: newBaseURL + 'category/getSuperCategoryList',
    // getUserDetail: baseURL + 'getUserDetail',
    getUserDetail: newBaseURL + 'customer/getUserDetail',
    // getcountries: baseURL + 'getCountries',
    getcountries: newBaseURL + 'customer/getCountries',
    // manageOrder: baseURL + 'manageOrder',
    manageOrder: newBaseURL + 'order/manageOrder',
    // orderDetail: baseURL + 'orderDetail',
    orderDetail: newBaseURL + 'order/orderDetail',
    // createOrder: baseURL + 'createOrder',
    createOrder: newBaseURL + 'order/createOrder',
    // updateProfile: baseURL + 'updateProfile',
    updateProfile: newBaseURL + 'customer/updateProfile',
    // myfavourites: baseURL + 'getFavouriteList',
    myfavourites: newBaseURL + 'product/getFavouriteList',
    // rating: baseURL + 'rating',
    rating: newBaseURL + 'product/rating',
    getProductListByCity: baseURL + 'getProductListByCity',
    // 14/dec/2019
    // trackOrder: baseURL + 'trackeOrder',
    trackOrder: newBaseURL + 'order/trackeOrder',
    // getSearcProductList: baseURL + 'getSearcProductList',  // user_id post
    getSearcProductList: newBaseURL + 'product/getSearcProductList',  // user_id post
    // getDeliveryAddress: baseURL + 'getDeliveryAddress', // user_id,device_id,search_key,page,   post
    getDeliveryAddress: newBaseURL + 'order/getDeliveryAddress', // user_id,device_id,search_key,page,   post
    // addDeliveryAddress: baseURL + 'addDeliveryAddress',
    addDeliveryAddress: newBaseURL + 'order/addDeliveryAddress',
    // getCouponList: commonBaseURL + 'getCouponList', // user_id  post
    getCouponList: newBaseURL + 'coupon/getCouponList', // user_id  post
    // getState: baseURL + 'getState', // country_id  post
    getState: newBaseURL + 'customer/getState', // country_id  post
    // getCity: baseURL + 'getCity', // state_id  post
    getCustomerCity: newBaseURL + 'customer/getCity', // state_id  post
    getProductsState: newBaseURL + 'customer/getCity', // state_id  post
    // removeDeliveryAddress: baseURL + 'removeDeliveryAddress', // user_id,delivery_id  post
    removeDeliveryAddress: newBaseURL + 'order/removeDeliveryAddress', // user_id,delivery_id  post
    cookRating: baseURL + 'cookRating', // user_id,cooking_id,rating,reviews  post
    get_city_data: baseURL + 'get_city_data', // user_id,cooking_id,rating,reviews  post
    // get_state_data: baseURL + 'get_state_data', // user_id,cooking_id,rating,reviews  post
    get_state_data: newBaseURL + 'customer/get_state_data', // user_id,cooking_id,rating,reviews  post
    // updateSellerAvailablity: baseURL + 'updateSellerAvailablity', // user_id,isAvailable  post
    updateSellerAvailablity: newBaseURL + 'customer/updateSellerAvailablity', // user_id,isAvailable  post
    // removeCart: baseURL + 'removeCart',
    removeCart: newBaseURL + 'cart/removeCart',
    // getAddressById: baseURL + 'getDeliveryAddressbyID',
    getAddressById: newBaseURL + 'order/getDeliveryAddressbyID',
    // getCheckOut: commonCheckoutURL + 'order/getCheckOut',
    getCheckOut: newBaseURL + 'order/getCheckOut',
    // rateproduct: baseURL + 'rating',
    rateproduct: newBaseURL + 'product/rating',
    // salewebAPI: baseURL + 'salewebAPI',
    salewebAPI: newBaseURL + 'product/saleWebAPI',
    CheckCouponCode: commonBaseURL + 'couponCheck',
    // CheckCouponCode: newBaseURL + 'coupon/couponCheck',
    checkSignupOTP: newBaseURL + 'customer/customerOtp',
    checkOTPForSignup: newBaseURL + 'customer/otpCheck',
    signUpOTP: newBaseURL + 'customer/otpLogin',
    signUpOTPs: newBaseURL + 'customer/checkOTP',
    // customerSignUp: newBaseURL + 'customer/signup',
    customerSignUps: newBaseURL + 'customer/updateName',
    downloadInvoice: commonCheckoutURL + 'Invoice/invoiceDetails',
};
