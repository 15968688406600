import { Component, OnInit, Inject } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { CommonService } from '../shared/common.service';

import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
declare  var $;
interface FoodNode {
  name: string;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Category',
    children: [
      { name: 'Apple' },
      { name: 'Banana' },
      { name: 'Fruit loops' },
    ]
  },
  {
    name: 'State',
    children: [
      { name: 'Apple' },
      { name: 'Banana' },
      { name: 'Fruit loops' },
    ]
  },
  {
    name: 'Price',
    children: [
      {
        name: 'Green',
        children: [
          { name: 'Broccoli' },
          { name: 'Brussels sprouts' },
        ]
      }, {
        name: 'Orange',
        children: [
          { name: 'Pumpkins' },
          { name: 'Carrots' },
        ]
      },
    ]
  },
  {
    name: 'Availibility',
    children: [
      { name: 'Apple' },
      { name: 'Banana' },
      { name: 'Fruit loops' },
    ]
  },
];
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  tabid = 0
  count = 0;
  subCategories : [] ;
  s_categories ;
  showCate : boolean = true;
  treeControl = new NestedTreeControl<FoodNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FoodNode>();
  constructor(private ngxService: NgxUiLoaderService,private spinner:NgxSpinnerService,public commonService: CommonService, private _bottomSheetRef: MatBottomSheetRef<FilterComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, @Inject(MAT_BOTTOM_SHEET_DATA) public category: any) {
    this.dataSource.data = TREE_DATA;
  }

  ngOnInit() {
  }

  trackElement(index: number, element: any) {

    return element ? element.guid : null
  }

  showSubcatrogie(id , catid){
  this.ngxService.start(); // start foreground loading with 'default' id
    this.spinner.show();
      
    this.tabid = id;  

    this.commonService.getSubCategoryByCatId(catid).subscribe((response) =>{
      if(response.code == 200){
        this.showCate = false; 
        this.s_categories = [];
        response.subcategory.forEach((cate ,i)=>{

          this.s_categories.push(cate);
          if(i == response.subcategory.length -1){
  
            this.ngxService.stop(); 
            this.spinner.hide(); 
            this.showCate = true; 
            // setTimeout(()=>{
              if(this.count == 0){
                this.count = 1;
                // this.showSubcatrogie(id , catid)
                $(`#${this.tabid}Categories`).click();
              }else{
                this.count = 0
              }
            // },1000)
          }

        })
      }
    })



  }



  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;
  openLink(event) {
    let data = {
      for: 'sortby',
      value: event
    }
    this._bottomSheetRef.dismiss(data);
    // event.preventDefault();
  }
  filtercategory(event) {
    console.log(event);
    let data = {
      for: 'filter',
      value: event
    }
    this._bottomSheetRef.dismiss(data);
    // event.preventDefault();
  }
}





