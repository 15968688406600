import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../shared/common.service';

@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.scss']
})
export class DashboardSidebarComponent implements OnInit {
  userId: any;
  public userData: any;
  public cartItemCount = 0;
  constructor(public router: Router, public commonService: CommonService) { }


  ngOnInit() {
    const userData = JSON.parse(localStorage.getItem('user'));
    this.userId = userData.id;

    this.userData = localStorage.getItem('user');
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
    }
  }
  logout() {
    localStorage.removeItem('user');
    // this.userData = null;
    // this.cartItemCount = 0;
    this.commonService.updateCartItemCount(0);
    window.open('/', "_self")
    // this.router.navigate(['/']);
    // window.location.reload()
  }
  initiateSave() {
    if (document.getElementById('save').style.display == 'block') {
      document.getElementById('username').setAttribute('disabled', 'disabled')
      document.getElementById('email').setAttribute('disabled', 'disabled')
      document.getElementById('mobile').setAttribute('disabled', 'disabled')
      document.getElementById('save').style.display = 'none';
      document.getElementById('cancel').style.display = 'none';
    }
    else {
      document.getElementById('username').removeAttribute('disabled')
      document.getElementById('email').removeAttribute('disabled')
      document.getElementById('mobile').removeAttribute('disabled')
      document.getElementById('save').style.display = 'block';
      document.getElementById('cancel').style.display = 'block';
    }
  }

}
