import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
// import { AuthService } from './auth.service';

@Injectable()

export class AuthGuard implements CanActivate {

  constructor(
    private router: Router, 
    // private authService: AuthService
    ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (localStorage.getItem('user')) {
      return true;
    }else{
        this.router.navigate(['/']);
    }
    // // not logged in so redirect to login page with the return url and return false
    // this.router.navigate(['authentication/login'], { queryParams: { returnUrl: state.url } });
    // return true;
  }
}
