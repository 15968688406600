import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../shared/common.service';
import { Router } from '@angular/router';
import { UrlConstants } from '../shared/url-constant';
import { Md5 } from 'ts-md5/dist/md5';
import {MustMatch} from '../_helpers/must-match.validator'
declare var $:any;

@Component({
  selector: 'app-login-signup-model',
  templateUrl: './login-signup-model.component.html',
  styleUrls: ['./login-signup-model.component.css']
})

export class LoginSignupModelComponent implements OnInit {
  createLoginForm: FormGroup;
  createSignupForm: FormGroup;
  createforgotForm: FormGroup;
  checkotpSignupForm:FormGroup
  mobileno = '';
  show: boolean;
  submitted1 = false;
  phonefind = '';
  submitted3 = false;
  submitted2 = false;
  errorMsgLogin = '';
  errorMsgForgot = '';
  signUpErrorMsg = '';
  signUpSuccessMsg = '';
  signupMobileNumber:any;
  otp:any;

  userData=false;
  
  @ViewChild('crossBtn', { static: false }) public crossBtn: ElementRef;
  @Output() public eventEmitter = new EventEmitter();
get f(){return this.createSignupForm.controls}
get g(){return this.createLoginForm.controls}
get h(){return this.createforgotForm.controls}
get i(){return this.checkotpSignupForm.controls}
  constructor(
    private formBuilder: FormBuilder,
    public commonService: CommonService,
    private router: Router
  ) { }
  ngOnInit() {
    this.createLoginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.createforgotForm = this.formBuilder.group({
      mobileno: ['', [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      confirmcode: ['']
    });
    this.checkotpSignupForm = this.formBuilder.group({
      number: ['', [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    });
    this.createSignupForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ])
      ],
      // number: ['', [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      referral_code:[],
      latitude:[],
      longitude:[],
      password: ['', Validators.required]
     });

    
  }
  delmsg() {
    this.errorMsgLogin = '';
  }
  resetform() {
    this.createLoginForm.reset()
    this.createforgotForm.reset()
    this.createSignupForm.reset()
  }
  forgot(values) {
    if (this.createforgotForm.invalid) {
      return;
    }
    if (!this.show) {
      this.commonService.post(UrlConstants.forgotPassword,{mobile_number:this.createforgotForm.value.mobileno}).subscribe(res => {
        if (res.status) {
          this.show = true
          this.phonefind = res.message
        } else {
          this.phonefind = res.message
        }
      })
    } else {
      this.commonService.post(UrlConstants.signUpOTPs, {mobile_number:this.createforgotForm.value.mobileno,OTP:this.createforgotForm.controls.confirmcode.value}).subscribe(res => {
        if (res.status && res.result && res.result.name && res.result.name !="") {
        const userdata = {
            id: res.result.user_id_PK,
            email: res.result.email,
            name: res.result.name,
            mobile: res.result.mobile,
            profile_picture: ''
          }
          console.log(userdata);
          
          localStorage.setItem('user', JSON.stringify(userdata));
          document.getElementById('closeforgot1').click();
          this.eventEmitter.emit(userdata);
          this.getCartList(userdata.id)
          this.show = true
          this.phonefind = res.message
          document.getElementById('closeforgot1').click();
        } else {
          this.phonefind = res.message
          this.user_id =res.result.user_id_PK
          this.signUpErrorMsg ="";
          document.getElementById('closeforgot1').click();
          document.getElementById('opensignupmodel').click();
        }
      })
    }
  }

  isPasswordShow=false;
  ShowAndHidePassword(){
    let type = $('#password_input').prop("type");
    if (type === "password") {
      this.isPasswordShow=true;
      $('#password_input').attr( 'type','text');
    } else {
      this.isPasswordShow=false;
      $('#password_input').attr( 'type','password');
    }
  }

  signUp() {
    this.errorMsgLogin = '';
    this.submitted2 = true;
    this.submitted1 = false;
    if (this.createSignupForm.invalid) {
      return;
    }
    this.commonService.post(UrlConstants.customerSignUps, {
      full_name: this.createSignupForm.controls.name.value,
      last_name: '',
      referral_code: this.createSignupForm.controls.referral_code.value,
      email: this.createSignupForm.controls.email.value,
      mobile_number: this.signupMobileNumber,
      latitude: '',
      longitude: '',
      password: this.createSignupForm.controls.password.value,
      device_id: '1234',
      user_id:this.user_id
    }).subscribe(data => {
      if (data && data.status === true) {
        this.errorMsgLogin = '';
        data = data.data;
        this.signUpSuccessMsg = 'You are registered successfully';
        this.commonService.post(UrlConstants.urlLogin, {
          email: this.createSignupForm.controls.email.value,
          password: this.createSignupForm.controls.password.value
        }).subscribe(data => {
          if (data && data.status === true) {
            this.errorMsgLogin = '';
            data = data.data;
            localStorage.setItem('user', JSON.stringify(data));
            document.getElementById('closeforgots').click();
            this.eventEmitter.emit(data);
            // alert('You are registered successfully');
          } else {
            this.errorMsgLogin = data.message;
          }
        });
      } else {
        this.signUpErrorMsg = data.message;
      }
    });
  }

  login() {
    this.submitted1 = true;
    this.submitted2 = false;
    if (this.createLoginForm.invalid) {
      return;
    }
    this.commonService.post(UrlConstants.urlLogin, {
      email: this.createLoginForm.controls.email.value,
      password: this.createLoginForm.controls.password.value
    }).subscribe(data => {
      if (data && data.status === true) {
        document.getElementById('closeforgot').click();
        this.errorMsgLogin = '';
        data = data.data;
        localStorage.setItem('user', JSON.stringify(data));
        this.eventEmitter.emit(data);
        // this.crossBtn.nativeElement.click();
        this.getCartList(data.id)
      } else {
        this.errorMsgLogin = data.message;
      }
    });
  }
  getCartList(id) {
    this.commonService.post(UrlConstants.getCartList, { user_id: id }).subscribe(data => {
      if (data.status === true) {
        this.commonService.updateCartItemCount(data.cartItemQuantity);
      }
    });
  }
  otpcheckfromAPI:any;
  otpCheck(){
    this.commonService.post(UrlConstants.signUpOTP,{ mobile_number: this.checkotpSignupForm.controls.number.value}).subscribe(data=>{
      console.log(data);
      
      if (data.status){
        if( data.data && data.data.OTP){
          data = data.data;
          localStorage.setItem('user', JSON.stringify(data));
          this.eventEmitter.emit(data);  
          document.getElementById('closeforgot').click();
        }
        else{
        // let hash = Md5.hashStr(data.otp);
        this.otpcheckfromAPI = data.otp;
        this.signUpErrorMsg = ""   
        document.getElementById('closeforgot').click();
        document.getElementById('openotpmodel').click();
        }
      }
    })
  }
  ShowMessage(){
    this.commonService.post(UrlConstants.signUpOTP,{ mobile_number: this.checkotpSignupForm.controls.number.value}).subscribe(data=>{
      if (data.status){
        this.signUpErrorMsg = "OTP resend Successfully"
      }
    })
  }
  user_id:any;
  submitOTP(){
        // let hash = Md5.hashStr(this.otp);
        // if(this.otpcheckfromAPI==hash){
          this.commonService.post(UrlConstants.signUpOTPs,{ mobile_number: this.checkotpSignupForm.controls.number.value,OTP:this.otp}).subscribe(data=>{
            if (data.status && data.result && data.result.name!=""){
              data = data.result;
              data.id=data.user_id_PK;
              console.log(data);
              
              localStorage.setItem('user', JSON.stringify(data));
              this.eventEmitter.emit(data);
              document.getElementById('closeforgot').click();
              document.getElementById('closeforgotss').click();
              // this.signUpErrorMsg = "OTP resend Successfully"
            }
            else if(data.status==false){
              this.signUpErrorMsg = data.message   
            }
            else{
              // data.result = data.result;
              this.user_id =data.result.user_id_PK
              this.signUpErrorMsg ="";
              document.getElementById('closeforgotss').click();
              document.getElementById('opensignupmodel').click();
          }
          })

          // }
          // else{
          //   this.signUpErrorMsg = "Enter the correct OTP"
          // }
  }
  
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
