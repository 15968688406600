import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';

import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    constructor(private router: Router, private ngxService: NgxUiLoaderService, private location: Location, private router1: Router) { }

    ngOnInit() {
        const mybutton = document.getElementById('myBtn');

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = () => { scrollFunction() };

        function scrollFunction() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                mybutton.style.display = 'block';
            } else {
                mybutton.style.display = 'none';
            }
        }

        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe((ev: any) => {
            if (ev instanceof NavigationStart) {
                if (ev.url !== this.lastPoppedUrl)
                    this.yScrollStack.push(window.scrollY);
            } else if (ev instanceof NavigationEnd) {
                if (ev.url === this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else
                    window.scrollTo(0, 0);
            }
        });
    }

    // When the user clicks on the button, scroll to the top of the document

    autoaunty() {
        // DOM Events
        document.addEventListener('mousemove', e => {
            this.resetTimer()
        })
    }

    async resetTimer() {
        let time;
        await clearTimeout(time)
        time = setTimeout(() => {
            document.getElementById('callaunty').click();
        }, 30000)
    }

    topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }



}








