import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelService } from '../model.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MatStepperModule } from '@angular/material/stepper';
import { OwlModule } from 'ngx-owl-carousel';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OverlayModule } from '@angular/cdk/overlay';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LoginSignupModelComponent } from '../login-signup-model/login-signup-model.component';
import { FilterComponent } from '../filter/filter.component';
import { ProductSliderComponent } from '../product-slider/product-slider.component';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { DashboardSidebarComponent } from '../dashboard-sidebar/dashboard-sidebar.component';
import { SpeechComponent } from '../speech/speech.component';
import { HighlightDirective } from '../directives/highlight.directive';
import { FilterPipe } from '../pipes/filter.pipe';
import { RatingComponent } from '../rating/rating.component';
import { SidebarComponent } from '../user-profile/sidebar/sidebar.component';



@NgModule({
  declarations: [
    ProductSliderComponent,
    LoginSignupModelComponent,
    HeaderComponent,
    FooterComponent,
    DashboardSidebarComponent,
    SpeechComponent,
    FilterComponent,
    HighlightDirective,
    FilterPipe,
    RatingComponent,
    DashboardSidebarComponent,
    SidebarComponent,
    DashboardSidebarComponent
  ],
  imports: [
    FormsModule,
      ReactiveFormsModule,
    RouterModule,
    CommonModule, 
    MatTreeModule,
    CarouselModule.forRoot(),
    OverlayModule,
    NgbModule,
    MatSidenavModule,
    MatBottomSheetModule,
    MatListModule,
    OwlModule,
    MatStepperModule,
    MDBBootstrapModule.forRoot(),
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatExpansionModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTableModule,
   InfiniteScrollModule,
    ScrollingModule,
  ],
  exports: [
    DashboardSidebarComponent,
    SidebarComponent,
    DashboardSidebarComponent,
    RatingComponent,
    ProductSliderComponent,
    LoginSignupModelComponent,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatTreeModule,
    CarouselModule,
    OverlayModule,
    NgbModule,
    MatSidenavModule,
    MatBottomSheetModule,
    MatListModule,
    OwlModule,
    MatStepperModule,
    MDBBootstrapModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatExpansionModule,
    HttpClientModule,
    ToastrModule,
    NgxSpinnerModule,
    CommonModule,
    MatSnackBarModule,
    MatTableModule,
    InfiniteScrollModule,
    MatPaginatorModule,
    ScrollingModule,
    HeaderComponent,
    FooterComponent,
    DashboardSidebarComponent,
    SpeechComponent,
    FilterComponent,
    HighlightDirective,
    FilterPipe,
  ],
  providers : [
    ModelService
  ],
  entryComponents: [FilterComponent ,LoginSignupModelComponent]
})
export class SharedModule { }
