import {
  Component,
  OnInit,
  AfterViewInit,
  Renderer2,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "../shared/common.service";
import { Product } from "../product";
import { UrlConstants } from "../shared/url-constant";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Meta, Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { Platform } from "@angular/cdk/platform";
import { getDiscountPercentages } from "../_helpers/must-match.validator";

declare var $: any;

@Component({
  selector: "app-prodesc",
  templateUrl: "./prodesc.component.html",
  styleUrls: ["./prodesc.component.scss"],
})
export class ProdescComponent implements OnInit, AfterViewInit {
  name = "NGX-UI-LOADER";
  public quantity = 1;
  public userId: number;
  public stock: boolean;
  private sub: any;
  public id: any;
  visible;
  device_id: 123456;
  index = 0;
  // public sellerProducts:[];
  public getDiscountPercentages = getDiscountPercentages;
  public sellerProducts = [];
  public slidesarray: [];
  public rate: any[];
  url = UrlConstants.getProductDetail;
  public item: Product;
  public relatedProducts: [];
  purl = window.location.href;
  itemsPerSlide = 5;
  singleSlideOffset = true;
  noWrap = true;
  catName: any;
  seller_id: any;
  free_sample: any;
  myurl: any;
  myurl1: any;
  whatsappUrl: any;
  twitterURL: any;
  faceBookUrl: any; 
  linkedin: any;
  googleURL: any;
  pinURL: any;

  vlog: boolean;
  repoUrl;
  @ViewChild("loginButton", { static: false })
  public loginButton: ElementRef<HTMLElement>;

  constructor(
    private title: Title,
    private meta: Meta,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private router: Router,
    private commonService: CommonService,
    private platform: Platform
  ) {}

  // openBottomSheet(): void {
  //   this._bottomSheet.open(BottomSheetOverviewExampleSheet);
  // }
  ngOnInit() {
    this.ngxLoader.start(); // start foreground loading with 'default' id

    this.catName = sessionStorage.getItem("catName");
    // Stop the foreground loading after 5s

    $(".owl-carousel").owlCarousel();
    this.route.params.subscribe((params) => {
      // this.myurl=params['id']
      // this.myurl1=this.myurl.replace('&marketplace=MOMSKART','')
      // var catid=this.myurl1.match(/id=([a-zA-Z]\W  )/)

      //  console.log("my url 1",this.myurl1)
      this.id = params.id;
      const userData = localStorage.getItem("user");
      this.userId = userData ? JSON.parse(userData).id : "";
      this.commonService
        .post(this.url, {
          product_slug: this.id,
          user_id: this.userId ? this.userId : "",
        })
        .subscribe((data) => {
          if (data.status === true) {
            this.item = data.result;
            console.log(data);

            this.repoUrl = location.href;
            this.whatsappUrl =
              "https://api.whatsapp.com/send?text=" + this.repoUrl;
            this.faceBookUrl =
              "https://www.facebook.com/sharer/sharer.php?u=" +
              encodeURIComponent(this.repoUrl);
            this.twitterURL =
              "https://twitter.com/intent/tweet/?text=" +
              this.item.description +
              "&url=" +
              encodeURIComponent(this.repoUrl);
            this.linkedin =
              "https://www.linkedin.com/shareArticle?url=" +
              encodeURIComponent(this.repoUrl);
            this.googleURL =
              "https://plus.google.com/share?url=" +
              encodeURIComponent(this.repoUrl);
            this.pinURL =
              "https://www.pinterest.com/pin/create/button/?url=" +
              encodeURIComponent(this.repoUrl) +
              "&description=" +
              this.item.description;

            this.title.setTitle(
              this.item.title +
                " - " +
                "Buy " +
                this.item.title +
                " online" +
                " at Momskart"
            );
            this.meta.updateTag({
              name: "description",
              content:
                "Buy " +
                this.item.title +
                ". Order best quality " +
                this.item.title +
                " made with mom's love at best prices. Delivering  homemade food products all over India. ",
            });
            this.meta.updateTag({
              name: "keywords",
              content:
                "Buy " +
                this.item.title +
                " , Buy " +
                this.item.title +
                " online , Buy " +
                this.item.title +
                " all over India , Buy best quality " +
                this.item.title +
                " , " +
                this.item.title +
                " , Buy snacks online , Buy Namkeen Online , Buy sweets online , Buy traditional food items online ",
            });

            this.getSellerProduct(data.result.seller_id);
            this.relatedProducts = data.related_product;
            this.ngxLoader.stop();
            this.rate = data.ratingList;
            this.stock =
              this.item.isOutOfStock == 1
                ? false
                : this.item.stock > 0
                ? true
                : false;
          }
        });
    });

    this.commonService.openLoginPopup().subscribe((data) => {
      console.log(data);
      if (data) {
        
        console.log(this.loginButton);
        this.loginButton.nativeElement.click();
      }
    });
  }

  copyInputMessage(inputElement) {
    console.log(inputElement, this.url);
    inputElement.select();
    document.execCommand("copy");
    inputElement.setSelectionRange(0, 0);
    this.toastr.success("Link has copied ", "Successfull");

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: ";
  }

  outFunc() {
    // var tooltip = document.getElementById("myTooltip");
    // tooltip.innerHTML = "Copy to clipboard";
  }

  shareToSocialMedia(socialMediaName) {
    $(".fa-share-alt").show();
    $(".dropdown-content").hide();

    if (socialMediaName == "Google+") {
      window.open(
        this.googleURL,
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    } else if (socialMediaName == "Pinterest") {
      console.log();
      window.open(
        this.pinURL,
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    } else if (socialMediaName == "WhatsApp") {
      if (!this.platform.IOS || !this.platform.ANDROID) {
        // let whatsappUrl= 'https://web.whatsapp.com/send?text=' +  this.whatsappUrl
        window.open(
          this.whatsappUrl,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes"
        );
      } else {
        let whatsappUrl = "https://wa.me/?text=" + this.whatsappUrl;
        window.open(
          whatsappUrl,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes"
        );
      }
    }
  }

  showShareButton() {
    if ($(".dropdown-content").css("display") == "none") {
      $(".fa-share-alt").hide();
      $(".dropdown-content").show();
      setTimeout(() => {
        $(".fa-share-alt").show();
        $(".dropdown-content").hide();
      }, 5000);
    } else {
      $(".fa-share-alt").show();
      $(".dropdown-content").hide();
    }
  }

  goToBack() {
    let catName = sessionStorage.getItem("catName");
    let catId = sessionStorage.getItem("catId");
    let supCatId = sessionStorage.getItem("supCatId");

    let slug =
      catName.replace(/\s+/g, "-") +
      "?cat_id=" +
      catId +
      "?sup_cat_id=" +
      supCatId +
      "&marketplace=MOMSKART";
    this.router.navigate(["/categorypage", slug]);
  }

  successRate() {
    // alert(" >> "+this.userId+" :: "+this.id);
    this.item = null;
    this.commonService
      .post(this.url, {
        product_slug: this.id,
        user_id: this.userId ? this.userId : "",
      })
      .subscribe((data) => {
        // console.log(data);
        if (data.status === true) {
          this.item = data.result;
          // console.log(this.item);
        }
      });
      
  }
  getSellerProduct(id) {
    this.commonService
      .post(UrlConstants.getProductList, {
        device_id: 1234,
        seller_id: id,
      })
      .subscribe((data) => {
        // console.log(data.result,'datatatatt')
        if (data["status"] === true) {
          data.result.forEach((element) => {
            this.sellerProducts.push(element);
          });
          //  console.log("seller product",this.sellerProducts)
          // let arr :any
          // let array: string[] = this.getSlides(data.result)
          // this.sellerProducts = []
          // for (var r in array) {
          //   this.sellerProducts.push(array[r])
          // }
          // this.sellerProducts=data.result
          // console.log(data)
          //  data.result.map(data,i=>{
          //  if(i%3===0){
          //  console.log(i,'iiiii')
        }
        //  })
        // }
      });
  }
  changepicture(index) {
    this.index = index;
  }

  getSlides(data) {
    let perslide = window.innerWidth > 1000 ? 6 : 1;
    let count = 0;
    let a = "slide" + count;
    let arr = [];
    arr[a] = [];
    data.map((re, i) => {
      if (i % perslide === 0) {
        count = count + 1;
        a = "slide" + count;
        arr[a] = [];
        arr[a].push(re);
      } else {
        arr[a].push(re);
      }
    });
    //  console.log(arr,"final")
    return arr;
  }
  // ngAfterViewInit() {
  //   const buttons = document.querySelectorAll('.btn-floating');
  //   buttons.forEach((el: any) => {
  //     this.renderer.removeClass(el, 'btn-floating');
  //     this.renderer.addClass(el, 'px-3');
  //     this.renderer.addClass(el.firstElementChild, 'fa-3x');
  //   });
  // }
  increment() {
    this.quantity += 1;
  }
  decrement() {
    if (this.quantity >= 1) {
      this.quantity -= 1;
    }
  }
  // getitem() {
  //   return this.prodlist.filter((item) => item.id === this.id);
  // }

  addToCart(productId, quantity) {
    this.ngxLoader.start(); // start foreground loading with 'default' id
    if (!this.userId) {
      const userData = localStorage.getItem("user");
      this.userId = userData ? JSON.parse(userData).id : "";
    }
    const response = this.commonService.addToCart(
      productId,
      quantity,
      this.userId
    );
    if (response) {
      response.subscribe((data) => {
        if (data.status === true) {
          this.ngxLoader.stop(); // start foreground loading with 'default' id
          // this.router.navigate(['/cart']);

          this.getCartList();
        } else {
          this.ngxLoader.stop(); // start foreground loading with 'default' id
          alert("Something went wrong, please try again later");
        }
      });
    } else {
      this.ngxLoader.stop(); // start foreground loading with 'default' id
    }
  }

  getCartList() {
    this.commonService
      .post(UrlConstants.getCartList, { user_id: this.userId })
      .subscribe((data) => {
        console.log(data);
        if (data.status === true) {
          this.commonService.updateCartItemCount(data.cartItemQuantity);
          this.commonService.announcedCard("item");
        }
      });
  }
  // openseller(id) {
  //   this.router.navigate(['/seller/' + id]);

  // }

  performAction(event) {
    // console.log(event,"userdata")
  }
  buyNow(productId, quantity) {
    if (this.userId != null) {
    } else {
      // console.log("default");
      // this.loginButton.nativeElement.click();
    }
    console.log("BUY NOW 11");
    if (!this.userId) {
      const userData = localStorage.getItem("user");
      this.userId = userData ? JSON.parse(userData).id : "";
      // console.log(this.userId);
    }

    const response = this.commonService.addToCart(
      productId,
      quantity,
      this.userId
    );
    console.log(response);
    if (response) {
      response.subscribe((data) => {
        if (data.status === true) {
          this.router.navigate(["/cart"]);
        } else {
          alert("Something went wrong, please try again later");
        }
      });
    }
  }

  buyNow1(productId, quantity) {
    if (!this.userId) {
      const userData = localStorage.getItem("user");
      this.userId = userData ? JSON.parse(userData).id : "";
    }
    const response = this.commonService.addToCart(
      productId,
      quantity,
      this.userId
    );
    if (response) {
      response.subscribe((data) => {
        if (data.status === true) {
          this.router.navigate(["/cart"]);
        } else {
          alert("Something went wrong, please try again later");
        }
      });
    }
  }

  addtoFav(productId) {
    console.log(productId+ " :: " +this.userId);
    // alert("here");
    const res = this.commonService.addToFav(productId, this.userId);
    // console.log(res);
    if (res) {
      res.subscribe((data) => {
        if (data.status === true) {
          this.ngOnInit();
        } else {
          alert("Something went wrong, please try again later");
        }
      });
    }
  }

  share(title, image, description) {
    console.log(title,image,description);
    
    let url = encodeURIComponent(this.purl);
    let imag = encodeURIComponent(image);
    let desc = encodeURIComponent(description);
    const meta = document.createElement("meta");
    meta.setAttribute("property", "og:image");
    meta.content = "" + image;
    document.getElementsByTagName("head")[0].appendChild(meta);

    const meta2 = document.createElement("meta");
    meta2.setAttribute("property", "og:title");
    meta2.content = "Results for: " + title;
    document.getElementsByTagName("head")[0].appendChild(meta2);
    this.meta.updateTag({ name: "og:image", content: image });
    // let url=encodeURIComponent(this.purl)
    setTimeout(() => {
      window.open(`https://api.whatsapp.com/send?text=${this.purl}`);
    }, 100);
  }

  newTab(id) {
    window.open("/productdescription/" + id, "_blank");
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.sellerProducts.length > 1) {
        this.visible = false;
      }
      // console.log("related products",this.sellerProducts)
    }, 2000);
  }
  openseller(id, catName) {
    let slug =
      catName.replace(/\s+/g, "-") + "-?cat_id=" + id + "&marketplace=MOMSKART";
    this.router.navigate(["/seller", slug]);
  }
}
