import { Component } from '@angular/core';
import Speech from 'speak-tts';

@Component({
    selector: 'app-speech',
    templateUrl: './speech.component.html',
    styleUrls: ['./speech.component.scss']
})
export class SpeechComponent {
    html = '';
    result = '';
    speech: any;
    speechData: any;
    showAunty = false;

    showStartButton = true;
    showPauseButton = false;
    showResumeButton = false;

    /* https://codesandbox.io/s/rmloxx60q4?file=/src/index.js */

    constructor() {
        this.speech = new Speech();
        this.speech.init({
            volume: 0.9,
            lang: 'en-GB',
            rate: 0.8,
            pitch: 1,
            //'voice':'Google UK English Male',
            //'splitSentences': false,
            listeners: {
                onvoiceschanged: voices => {

                }
            }
        }).then(data => {
            
        })
            .catch(e => {
                console.error('An error occurred while initializing : ', e);
            });
    }

    start() {
        this.speech.setLanguage('hi-IN');
        this.speech.setVoice('Google हिन्दी');
        this.speech.speak({
            text: 'Namaste, Mai Maahi Momskart me apka swagat he, Momskart India ka apna online marketplace he jha pr apko milege India ke best homemade food items  India k  alg alg cultures se  Aaj ka special  he , Homemade special Dry Kachori , by Mrs Ganatra from Indore  Homemade special Papad , by Mrs Dixita Trivedi from Ahmedabad Homemade special Chocolate by Mrs Alefiyah from Odisha',
            queue: false,
            listeners: {
                onstart: () => {
                   
                },
                onend: () => {
                
                    this.showStartButton = true;
                    this.showPauseButton = false;
                    this.showResumeButton = false;
                },
                onresume: () => {
                   
                },
                onboundary: event => {
                  
                },
            }
        })
            .then(data => {
                // setTimeout(() => {
                document.getElementById('aunty').click();
                // }, 100);
              
            })
            .catch(e => {
                console.error('An error occurred :', e);
            });
        this.showStartButton = false;
        this.showPauseButton = true;
        this.showResumeButton = false;
    }

    pause() {
        this.showStartButton = false;
        this.showPauseButton = false;
        this.showResumeButton = true;
        this.speech.pause();
    }

    resume() {
        this.showStartButton = false;
        this.showPauseButton = true;
        this.showResumeButton = false;
        this.speech.resume();
    }

    hideAunty() {
        if (this.showPauseButton) {
            this.pause();
        }
        this.showAunty = false;
        // this.showAunty = true;
    }
}
