import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { OwlModule } from 'ngx-owl-carousel';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { OurPolicyComponent } from './our-policy/our-policy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { HttpClientModule } from '@angular/common/http';
import { CommonService } from './shared/common.service';
import { AboutComponent } from './about/about.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { MatStepperModule } from '@angular/material/stepper';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { SpeechComponent } from './speech/speech.component';
import { FilterComponent } from './filter/filter.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatTreeModule } from '@angular/material/tree';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner'
import { CommonModule } from '@angular/common'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTableModule } from '@angular/material/table'
import { ModelService } from './model.service'
import { HighlightDirective } from './directives/highlight.directive'
import { FilterPipe } from './pipes/filter.pipe'
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from './shared/shared.module';
import { ProdescComponent } from './prodesc/prodesc.component';
import { AuthGuard } from './auth.guard';
import { NgOtpInputModule } from 'ng-otp-input';
import { SitemapComponent } from './sitemap/sitemap.component';

import { CheckoutModule } from 'paytm-blink-checkout-angular';
// import { LocationStrategy, HashLocationStrategy } from '@angular/common';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // bgsColor: '#ff0045',
  "bgsColor": "#ff0045",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "delay": 0,
  // "fastFadeOut": true,
  "fgsColor": "#ff0045",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "ball-spin-clockwise",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "#ff0045",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#fff",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
};



const routes: Routes = [
  {
    path: 'seller/:id',
    loadChildren: './seller-shop/seller.shop.module#SellerShopModule',

  },
  {
    path: 'sellonmomskart',
    loadChildren: './sellonmomskart/sellonmomskart.module#SellonmomskartModule',

  },
  {
    path: 'orderDetail',
    loadChildren: './orderdetail/orderdetail.module#OrderdetailModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'invoice',
    loadChildren: './invoice/invoice.module#InvoiceModule',
    canActivate: [AuthGuard],
  },

  {
    path: 'ManageAddress',
    loadChildren: './addressbook/addressbook.module#AddressbookModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'sorry',
    loadChildren: './payment-fail/payment-fail.module#PaymentFailModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: './login-signup-model/login-signup-model.module#LoginSignupModelModule',

  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'editaccount/:id',
    loadChildren: './edit-account/edit-account.module#EditAccountModule',
    canActivate: [AuthGuard],

  },
  {
    path: 'addnewaddress',
    loadChildren: './edit-address/edit-address.module#EditAddressModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'editaddress/:id',
    // component: EditAddressComponent,
    loadChildren: './edit-address/edit-address.module#EditAddressModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'MyAccount',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard],
  },
  {

    path: 'cart',
    loadChildren: './cart-version2/cart-version2.module#CartVersion2Module',
    canActivate: [AuthGuard],
  },
  {
    path: 'ourpolicy',
    component: OurPolicyComponent,
  },
  {
    path: 'sitemap',
    component: SitemapComponent,
  },
  {
    path: 'termscondition',
    component: TermsConditionComponent,
  },

  {
    path: 'productdescription/:id',
    component: ProdescComponent,
    // loadChildren: './prodesc/prodesc.module#ProdescModule',

  },
  {
    path: 'categorypage/:super_cat_id/:cat_id',
    // component: CategoryComponent
    loadChildren: './category/category.module#CategoryModule',
  },
  {
    path: 'categorypage/:cat_id',
    // component: CategoryComponent
    loadChildren: './category/category.module#CategoryModule',
  },
  {
    path: 'categorypage/city/:name/:city_id',
    // component: CategoryComponent
    loadChildren: './category/category.module#CategoryModule',


  },
  {
    path: 'contact',
    component: ContactComponent,


  },
  {
    path: 'special',
    // component: SpecialComponent
    loadChildren: './special/special.module#SpecialModule',

  },
  {
    path: 'foodiecash',
    // component: FoodieCashComponent
    loadChildren: './foodie-cash/foodie-cash.module#FoodieCashModule',

  },
  {
    path: 'myFavorites',
    // component: MyfavoritesComponent
    loadChildren: './myfavorites/myfavorites.module#MyfavoritesModule',
    canActivate: [AuthGuard],
  },

  {
    path: 'myOrder',
    // component: OrdersComponent
    loadChildren: './orders/orders.module#OrdersModule',
    canActivate: [AuthGuard],
  },
  {
    path: '',
    // component: IndexComponent
    loadChildren: () => import('./index/index.module').then(m => m.IndexModule)
    // loadChildren: './index/index.module#IndexModule',
  },
  {
    path: 'rating',
    // component: RatingComponent
    loadChildren: './rating/rating.module#RatingModule',

  },
  {
    path: 'suscribe-nsave',
    // component: SuscribeNsaveComponent
    loadChildren: './suscribe-nsave/suscribe-nsave.module#SuscribeNsaveModule',

  },
  {
    path: 'sale',
    // loadChildren: () => import('./sale/sale.module').then(m => m.SaleModule) 
    loadChildren: './sale/sale.module#SaleModule',

    // component: SaleComponent
  },
  {
    path: 'userProfile',
    // loadChildren: () => import('./user-profile/user-profile-routing-module').then(m => m.UserProfileRoutingModule) 
    loadChildren: './user-profile/user-profile-routing-module#UserProfileRoutingModule',
    canActivate: [AuthGuard],
  },


];
@NgModule({
  declarations: [
    AppComponent,
    ProdescComponent,
    ContactComponent,
    OurPolicyComponent,
    TermsConditionComponent,
    AboutComponent,
    SitemapComponent,

  ],
  imports: [
    RouterModule.forRoot(routes, { useHash: false }),
    NgOtpInputModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    MatTreeModule,
    CarouselModule.forRoot(),
    OverlayModule,
    NgbModule,
    MatSidenavModule,
    MatBottomSheetModule,
    MatListModule,
    OwlModule,
    MatStepperModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatExpansionModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    CommonModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    InfiniteScrollModule,
    ScrollingModule,
    SharedModule,
    CheckoutModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  entryComponents: [FilterComponent],
  providers: [CommonService,
    { provide: APP_BASE_HREF, useValue: '/' },
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // },
    NgxSpinnerService, ModelService, AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
