import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public router: Router) { }
  currentyear = new Date().getFullYear()
  slides = [
    { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/1.jpg' },
    { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/2.jpg' },
    { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/3.jpg' },
    { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/4.jpg' },
    { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/5.jpg' },
    { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/6.jpg' },
    { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/7.jpg' },
    { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/8.jpg' },
    { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/1.jpg' },
    { image: 'https://valor-software.com/ngx-bootstrap/assets/images/nature/2.jpg' }
  ];
  ngOnInit() {
  }
  sendPlayStore() {
    window.open('https://play.google.com/store/apps/details?id=in.sellers.momskart&hl=en_IN', '_blank');
  }


}
