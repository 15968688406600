import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../shared/common.service';
import { UrlConstants } from '../shared/url-constant';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-header', 
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public userData: any;
  public cartItemCount = 0;
  searchForm: FormGroup;
  menuItems = [];
  searched_data = []
  searchText: string;
  subscription: Subscription;
  isSearching = false;
  @ViewChild('loginButton', { static: false }) public loginButton: ElementRef<HTMLElement>;
  @ViewChild('searchBarEl', { static: true }) searchBarEl: ElementRef;
  @ViewChild('searchBarMobEl', { static: true }) searchBarMobEl: ElementRef;

  constructor(public router: Router, public commonService: CommonService, private formBuilder: FormBuilder) {
    this.subscription = this.commonService.announcedCardItem$.subscribe(item => {
      this.commonService.getCartItemCount().subscribe(data => {
        if (data >= 0) {
          this.cartItemCount = data;
        }
      });
    });


  }

  ngOnInit() {
    const userData = localStorage.getItem('user');
    const userId = userData ? JSON.parse(userData).id : '';

    this.commonService.post(UrlConstants.getCartList, { user_id: userId }).subscribe(data => {
      if (data.status === true) {
        this.cartItemCount = data.cartItemQuantity;
      }
    });

    this.commonService.getCartItemCount().subscribe(data => {
      if (data >= 0) {
        this.cartItemCount = data;
      }
    });
    this.searchForm = this.formBuilder.group({
      serchData: ['', Validators.required],
    });

    this.commonService.get(UrlConstants.getMenuItems).subscribe(data => {
      if (data.status === true && data.code === 200) {
        console.log(data);
        
        this.menuItems = data.super_cat_list;
      }
    });

    this.commonService.openLoginPopup().subscribe(data => {
      if (data) {
        this.loginButton.nativeElement.click();
      }
    });



    this.commonService.getCartItemCount().subscribe(data => {
      if (data >= 0) {
        this.cartItemCount = data;
      }
    });

    this.userData = localStorage.getItem('user');
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
    }
    this.setupSearchSubscription('searchBarEl');
    this.setupSearchSubscription('searchBarMobEl');
    //   $('body').click(function() {
    //     document.getElementById('mainnavbutton').click()
    //   });
    //   $('navbar-toggler').click(function(event){
    //     event.stopPropagation();
    //  });
  }

  setupSearchSubscription(inputElement) {
    fromEvent(this[inputElement].nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        // console.log(event);

        const searchedText = event.target.value;
        if (searchedText === '') {
          this.searched_data = [];
        }
        return searchedText;
      })
      // if character length greater then 2
      , filter(res => (res.length > 1))
      // Time in milliseconds between key events
      , debounceTime(200)
      // If previous query is diffent from current
      , distinctUntilChanged()
      // subscription for response
    ).subscribe(async (text: string) => {
      this.isSearching = true;
      await this.search(text);
      //  this.search('');
    });

  }

  performAction(event) {
    this.userData = event;
  }

  logout() {
    localStorage.removeItem('user');
    this.userData = null;
    this.cartItemCount = 0;
    this.router.navigate(['/']);
  }

  newTab(id, catName, item,) {
    sessionStorage.setItem('catName', catName);
    sessionStorage.setItem('product_title', item.title);
    const slug = catName.replace(/\s+/g, '-') + '?cat_id' + id + '&marketplace=MOMSKART';
    //if (window.innerWidth < 1000)
    // document.getElementById('mainnavbutton').click()
    this.router.navigate(['/productdescription', item.slug]);
    this.searchText = '';
    this.searched_data = [];
  }

  search(text: string) {
    const form = new FormData();
    form.append('search_key', text);
    form.append('user_id', this.userData !== null ? this.userData.id : 0);
    form.append('device_id', 'f73150ae64d0effb');
    this.commonService.getSearcProductList(form).subscribe(data => {
      if (data.result) {
        this.searched_data = data.result;
      }
      this.isSearching = false
    })
  }

  cart() {
    if (this.userData != null) {
      this.router.navigate(['/cart']);
      // document.getElementById('mainnavbutton').click()
    } else {
      this.loginButton.nativeElement.click();
    }
    // console.log("Button clicked")
  }

  // cart1() {
  //   if (this.userData != null) {
  //     this.router.navigate(['/cart']);
  //   } else {
  //     this.loginButton.nativeElement.click();
  //   }
  // }

  moveToCategoryPage(supCatId, catId, catName,state_id:string) {
    console.log(supCatId,catId,catName,state_id);
    
    sessionStorage.setItem('catName', catName);
    sessionStorage.setItem('catId', catId);
    sessionStorage.setItem('supCatId', supCatId);
    sessionStorage.setItem('state_id', state_id);
    const slug = catName.replace(/\s+/g, '-') + '?cat_id=' + catId + '?sup_cat_id=' + supCatId + '&marketplace=MOMSKART' + '?state_id=' + state_id;
    this.router.navigate(['/categorypage', slug]);
    if (window.innerWidth < 1000)
      document.getElementById('mainnavbutton').click()
  }
  hideonmobileonclick(ev:any){

  }
}
