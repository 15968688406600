import { Injectable, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { UrlConstants } from "./url-constant";

@Injectable()
export class CommonService implements OnInit {
  constructor(private http: HttpClient) {}
  userId: any;
  device_id: 123456;
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });

  options = { headers: this.headers };
  private loginCheck = new Subject<any>();
  public cartItemCount = new Subject<any>();

  private announceCard = new Subject<any>();
  announcedCardItem$ = this.announceCard.asObservable();

  ngOnInit() {
    const userData = localStorage.getItem("user");
    const data = JSON.parse(userData);
    this.userId = userData ? data.id : "";
  }

  announcedCard(points: string) {
    console.log(points);
    this.announceCard.next(points);
  }

  public post(url, data): Observable<any> {
    const header = new HttpHeaders({ "Content-Type": "application/json" });

    header.append("Access-Control-Allow-Origin", "*");
    header.append(
      "Access-Control-Request-Method",
      "POST, GET, OPTIONS, PUT, DELETE, HEAD, PATCH"
    );
    header.append("Access-Control-Request-Headers", "*");
    header.append("Access-Control-Allow-Credentials", "true");
    return this.http.post(url, data, { headers: header });
  }

  public postAuth(url, data): Observable<any> {
    const header = new HttpHeaders({
      Authorization:
        "25065950-d194-11ea-9585-2fd2bb9bf94b:47ec02cfdea8635fa8a4444ddecbec14b8841c35",
    });
    // 25065950-d194-11ea-9585-2fd2bb9bf94b:47ec02cfdea8635fa8a4444ddecbec14b8841c35 // live
    // a421a2c0-cdb7-11ea-aabb-911f5edc931e:10523fd1e6303274fd09890dcb55d47b92780966 // Sandbox
    header.append("Content-Type", "application/json");
    header.append("Access-Control-Allow-Origin", "*");
    header.append(
      "Access-Control-Request-Method",
      "POST, GET, OPTIONS, PUT, DELETE, HEAD, PATCH"
    );
    header.append("Access-Control-Request-Headers", "*");
    header.append("Access-Control-Allow-Credentials", "true");

    return this.http.post(url, data, { headers: header });
  }

  public get(url): Observable<any> {
    return this.http.get<any>(url);
  }

  public checkLogin() {
    const userData = localStorage.getItem("user");
    if (userData) {
      const userDetails = JSON.parse(userData);
      return userDetails.id;
    }
    return false;
  }

  public salewebAPI(): Observable<any> {
    return this.http.post(UrlConstants.salewebAPI, {});
  }

  public CheckCouponCode(userId) {
    return this.http.post(UrlConstants.CheckCouponCode, { user_id: userId });
  }

  public addToCart(productId, productQuantity, userId): Observable<any> {
    if (!this.checkLogin()) {
      this.loginCheck.next(true);
      return null;
    }
    return this.http.post(UrlConstants.addToCart, {
      product_id: productId,
      user_id: userId,
      quantity: productQuantity,
    });
  }

  public addInstruction(
    productId,
    instruction,
    quantity,
    userId
  ): Observable<any> {
    if (!this.checkLogin()) {
      this.loginCheck.next(true);
      return null;
    }
    const data = JSON.stringify({
      product_id: productId,
      user_id: userId,
      instruction,
      quantity,
    });
    return this.http.post(UrlConstants.addToCart, data);
  }

  public openLoginPopup(): Observable<any> {
    return this.loginCheck.asObservable();
  }

  public updateCartItemCount(count: number) {
    this.cartItemCount.next(count);
  }

  public getCartItemCount() {
    return this.cartItemCount.asObservable();
  }

  public getCurrentBestSellers(id) {
    return this.http.post(UrlConstants.getHomeData, id);
  }

  public addToFav(productId, userId): Observable<any> {
    if (!this.checkLogin()) {
      this.loginCheck.next(true);
      // console.log("here");
      return null;
    }
    return this.http.post(UrlConstants.addToFavourite, {
      product_id: productId,
      user_id: userId,
    });
  }
  public getCategoryAll(): Observable<any> {
    return this.http.get(UrlConstants.getCategoryAll);
  }

  addHeader() {
    const options = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "https://themomskart.com",
        "Content-Type": "application/json",
        Accept: "*/*",
        Host: "themomskart.com",
      }),
    };
    return options;
  }

  public getProductListByCity(): Observable<any> {
    return this.http.get(UrlConstants.getProductListByCity);
  }

  public getSubCategoryByCatId(cateId): Observable<any> {
    const form = new FormData();
    form.append("cat_id", cateId);
    return this.http.post(UrlConstants.getSubcategories, form);
  }

  public getCategoryBySuperCat(cateId): Observable<any> {
    const form = new FormData();
    form.append("super_cat_id", cateId);
    return this.http.post(UrlConstants.getCategoryBySuperCat, form);
  }

  public getUserdetails(id): Observable<any> {
    const form = new FormData();
    form.append("user_id", id);
    return this.http.post(UrlConstants.getUserDetail, form);
  }

  public getMyFavourites(userId, deviceId): Observable<any> {
    const form = new FormData();
    form.append("user_id", userId);
    form.append("device_id", deviceId);
    return this.http.post(UrlConstants.myfavourites, form);
  }

  public addAddress(info) {
    return this.http.post(UrlConstants.addDeliveryAddress, info);
  }

  public getAddress(id): Observable<any> {
    const form = new FormData();
    form.append("user_id", id);
    return this.http.post(UrlConstants.getDeliveryAddress, form);
  }

  public getStateByCountryId(id): Observable<any> {
    const form = new FormData();
    form.append("country_id", id);
    return this.http.post(UrlConstants.getState, form);
  }

  public getCitysByCountryId(id): Observable<any> {
    const form = new FormData();
    form.append("state_id", id);
    form.append("device_id", "123456");
    form.append("user_id", "5197");

    return this.http.post(UrlConstants.getCustomerCity, form);
  }

  public getSearcProductList(form): Observable<any> {
    return this.http.post(UrlConstants.getSearcProductList, form);
  }

  public getCouponList(): Observable<any> {
    return this.http.get(UrlConstants.getCouponList);
  }

  public createOrder(data): Observable<any> {
    return this.http.post(UrlConstants.createOrder, data);
  }

  public manageOrder(data): Observable<any> {
    const form = new FormData();
    form.append("user_id", data);
    return this.http.post(UrlConstants.manageOrder, form);
  }

  public getOrderDetail(userId, orderId): Observable<any> {
    const form = new FormData();
    form.append("user_id", userId);
    form.append("order_id", orderId);
    return this.http.post(UrlConstants.orderDetail, form);
  }
  public downloadInvoice(userId, order_id): Observable<any> {
    const form = new FormData();
    // form.append('user_id', userId);
    form.append("orderId", order_id);
    return this.http.post(UrlConstants.downloadInvoice, form);

    // return this.http.post(UrlConstants.downloadInvoice, data);
  }

  public updatePassword(userId, oldPass, newPass): Observable<any> {
    const form = new FormData();
    form.append("user_id", userId);
    form.append("old_password", oldPass);
    form.append("new_password", newPass);
    return this.http.post(UrlConstants.updatePassword, form);
  }

  public updateUser(data, userId): Observable<any> {
    const form = new FormData();
    form.append("user_id", userId);
    form.append("name", data.first_name);
    form.append("email", data.email);
    form.append("mobile", data.mobile_number);
    return this.http.post(UrlConstants.updateProfile, form);
  }

  public removeCartItem(userId, cartId): Observable<any> {
    const form = new FormData();
    form.append("user_id", userId);
    form.append("cart_id", cartId);
    form.append("device_id", "1234");
    return this.http.post(UrlConstants.removeCart, form);
  }

  public getAddressById(userId, addressId): Observable<any> {
    const form = new FormData();
    form.append("user_id", userId);
    form.append("delivery_id", addressId);
    return this.http.post(UrlConstants.getAddressById, form);
  }

  public getCheckOut(userId, pincode): Observable<any> {
    // const form = new FormData();
    // form.append('user_id', userId);
    // form.append('customer_pincode', pincode);
    // form.append('device_id', '1234');
    const data = JSON.stringify({
      user_id: userId,
      device_id: "12345",
      customer_pincode: pincode,
    });

    return this.http.post(UrlConstants.getCheckOut, data);
  }

  public removeDeliveryAddress(userId, addressId): Observable<any> {
    const form = new FormData();
    form.append("user_id", userId);
    form.append("delivery_id", addressId);
    return this.http.post(UrlConstants.removeDeliveryAddress, form);
  }

  public country() {
    return this.http.get(UrlConstants.getcountries);
  }
}
